<template>
  <div class="col-lg-12 col-md-12">
    <CRow>
      <CCol md="7" class="">
        <h4 class="text-primary mb-3 mt-3">Registered Campaign</h4>
        <div
          class="w-100 p-3 registered-campaign"
          style="background-color: white"
        >
          <ul class="list-group">
            <CRow class="col-12 p-0 d-flex">
              <CCol class="col-7">
                <CInput 
                  v-model="searchTerm" 
                  placeholder="Search Campaigns"
                  @keydown.enter="handleSearchTerm(searchTerm)" />
              </CCol>
              <CCol class="col-4">
                <CButton
                  @click="handleSearchTerm(searchTerm)"
                  class="btn btn-primary mr-3"
                >
                  <i class="fas fa-search"></i>
                </CButton>
                <CButton
                  v-if="searchTerm"
                  @click="clearSearchTerm()"
                  class="btn btn-primary mr-3"
                >
                  <i class="fas fa-times"></i>
                </CButton>
              </CCol>
              <CCol class="col-1">
                <CDropdown
                  size="sm"
                  toggler-text
                  class="mr-2 text-right d-flex justify-content-lg-end"
                  :caret="false"
                  id="dropdownMenu2"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <template #toggler-content>
                    <CButton class="btn btn-primary text-white">Filter</CButton>
                  </template>
                  <CDropdownItem @click="showCampaignFilter(true)"
                    >Active</CDropdownItem
                  >
                  <CDropdownItem @click="showCampaignFilter(false)"
                    >InActive</CDropdownItem
                  >
                </CDropdown>
              </CCol>
            </CRow>
            <div class="mt-2 filter-details d-inline-block mb-2" v-if="searched">
              <div class="filter-details-item d-flex vs__selected-options">
                <span>Search Term :</span>
                  <div class="m-0 ml-1 vs__selected">
                    <span>
                    {{ searched }}
                  </span>
                  <button
                    type="button"
                    aria-label="Deselect option"
                    class="vs__deselect"
                    @click="clearSearchTerm()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                    >
                      <path
                        d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div
              class="mt-2 filter-details d-inline-block mb-2"
              v-if="checkFilterIsActive !== undefined"
            >
              <div class="filter-details-item d-flex vs__selected-options">
                <span>Filter :</span>
                <div class="m-0 ml-1 vs__selected">
                  <span>
                    {{ checkFilterIsActive ? "Active" : "InActive" }}
                  </span>
                  <button
                    type="button"
                    aria-label="Deselect option"
                    class="vs__deselect"
                    @click="clearIsActiveFilter()"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                    >
                      <path
                        d="M6.895455 5l2.842897-2.842898c.348864-.348863.348864-.914488 0-1.263636L9.106534.261648c-.348864-.348864-.914489-.348864-1.263636 0L5 3.104545 2.157102.261648c-.348863-.348864-.914488-.348864-1.263636 0L.261648.893466c-.348864.348864-.348864.914489 0 1.263636L3.104545 5 .261648 7.842898c-.348864.348863-.348864.914488 0 1.263636l.631818.631818c.348864.348864.914773.348864 1.263636 0L5 6.895455l2.842898 2.842897c.348863.348864.914772.348864 1.263636 0l.631818-.631818c.348864-.348864.348864-.914489 0-1.263636L6.895455 5z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <li
              class="list-group-item"
              v-for="(campaign, index) in campaignsList"
              :key="index"
            >
              <div class="row align-items-center">
                <div class="col-md-9 d-flex">
                  <div class="logo">
                    <img
                      :src="campaign._logo"
                      class="rounded-circle"
                      @error="$event.target.src = '/img/tflogo.png'"
                    />
                  </div>
                  <div class="ml-3">
                    <h5 class="mb-1">
                      <i
                        v-if="!campaign.campaign_registration_required"
                        class="fa-solid fa-registered text-primary"
                        v-c-tooltip="'Direct registration'"
                      ></i>
                      {{ campaign.campaign_name }}
                      <CBadge
                        style="font-size: 10px"
                        :color="campaign.is_active ? 'success' : 'danger'"
                        small
                      >
                        {{ campaign.is_active ? "Active" : "Inactive" }}
                      </CBadge>
                      <CButton type="button" @click="campaignEdit(campaign)">
                        <i
                          class="fas fa-edit"
                          v-c-tooltip="'Edit Campaign'"
                        ></i>
                      </CButton>
                    </h5>
                    <p
                      class="mb-0 text-muted small"
                      v-if="campaign.description"
                    >
                      <span
                        v-if="showDetails && index === selectedDescriptionIndex"
                      >
                        {{ campaign.description }}
                        <a
                          href="#"
                          class="underline-link"
                          @click="showDescription(index)"
                          >Show Less</a
                        >
                      </span>
                      <span v-else>
                        {{ campaign.description.substring(0, 60) }}
                        <a
                          href="#"
                          class="underline-link"
                          v-if="campaign.description.length > 60"
                          @click="showDescription(index)"
                          >Read More...</a
                        >
                      </span>
                    </p>
                    <p class="mb-0 text-muted small">
                      <span class="font-weight-bold">Campaign ID:</span>
                      {{ campaign.campaign_id }}
                    </p>
                    <p class="mb-0 text-muted small">
                      <span class="font-weight-bold">Created:</span>
                      {{ formatDate(campaign.created_on) }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3 d-flex justify-content-end">
                  <div class="d-flex">
                    <CButton type="button" @click="showQr(campaign)">
                      <i class="fa-solid fa-qrcode" v-c-tooltip="'QR Code'"></i>
                    </CButton>
                    <CButton type="button" @click="goToCampaignForm(campaign)">
                      <i class="fas fa-edit" v-c-tooltip="'Edit Questions'"></i>
                    </CButton>
                    <CButton
                      type="button"
                      @click="
                        fileDownload(
                          campaign.campaign_name,
                          campaign.campaign_id
                        )
                      "
                    >
                      <i
                        class="fa-solid fa-download"
                        v-c-tooltip="'Download'"
                      ></i>
                    </CButton>
                    <CButton @click="copyUrl(campaign)">
                      <i class="fa-solid fa-clone" v-c-tooltip="'Copy URL'"></i>
                    </CButton>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <CPagination
            class="p-4 d-flex justify-content-end"
            v-if="campaignsList.length"
            :activePage="currentPage"
            :pages="pageCount"
            @update:activePage="pageChanged"
          />
          <p class="h5 text-center text-muted m-3" v-if="!campaignsList.length">
            No Campaign Found
          </p>
        </div>
      </CCol>

      <CCol md="5" :style="{ display: !showCampaignEdit ? 'block' : 'none' }">
        <h4 class="text-primary mb-3 mt-3">Register Campaign</h4>
        <CCard class="shadow p-3 mb-5 bg-white rounded">
          <CCardBody>
            <ValidationObserver
              ref="campaignRegister"
              v-slot="{ handleSubmit }"
            >
              <form
                @submit.prevent="handleSubmit(onSubmit)"
                name="campaignRegister"
              >
                <CRow class="mb-3">
                  <label class="required col-12">Campaign Name</label>
                  <div class="col-11">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="campaign_name"
                        placeholder="Enter Campaign Name"
                        :value="campaign.campaign_name"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Welcome Text</label>
                  <div class="col-11">
                    <TextInput
                      name="welcome_text"
                      placeholder="Enter Welcome Text"
                      :value="campaign.welcome_text"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Description</label>
                  <div class="col-11">
                    <TextareaInput
                      name="description"
                      :value="campaign.description"
                      @input="handleInput"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Upload Logo</label>
                  <div class="col-12">
                    <input
                      id="imageUpload"
                      type="file"
                      ref="logo"
                      name="logo"
                      :accept="fileType"
                      @change="handleFile"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Upload Background</label>
                  <div class="col-12">
                    <input
                      name="background_image"
                      id="imageUpload"
                      type="file"
                      ref="background_image"
                      :accept="fileType"
                      @change="handleFile"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="pt-1 col-lg-4 col-md-4 col-6"
                    >Enable pre-registration?</label
                  >
                  <CSwitch
                    name="campaign_registration_required"
                    class="mt-1"
                    color="success"
                    :checked="campaign.campaign_registration_required"
                    shape="pill"
                    variant="3d"
                    @change.native="
                      handleChangeCheck(
                        'campaign_registration_required',
                        $event
                      )
                    "
                  />
                </CRow>
                <CRow class="justify-content-end pr-3 mb-3">
                  <CButton
                    variant="outline"
                    color="primary"
                    class="mr-3"
                    type="button"
                    @click="resetForm()"
                    >Cancel</CButton
                  >
                  <CButton
                    class="btn-primary"
                    type="submit"
                    :disabled="uploading"
                    ><CSpinner
                      v-if="uploading"
                      class="spinner-border-sm text-white m-1"
                    />
                    Create
                  </CButton>
                </CRow>
              </form>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="5" v-if="showCampaignEdit">
        <h4 class="text-primary mb-3 mt-3">
          Edit Campaign -
          {{ campaignsList[selectedCampaignIndex].campaign_name }}
        </h4>
        <CCard class="shadow p-3 mb-5 bg-white rounded">
          <CCardBody>
            <ValidationObserver
              ref="editcampaigndetails"
              v-slot="{ handleSubmit }"
            >
              <form
                @submit.prevent="handleSubmit(onUpdate)"
                name="editcampaigndetails"
              >
                <CRow class="mb-3">
                  <label class="required col-12">Campaign Name</label>
                  <div class="col-11">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextInput
                        name="campaign_name"
                        :value="campaignData.campaign_name"
                        @input="updateHandleInput"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Welcome Text</label>
                  <div class="col-11">
                    <TextInput
                      name="welcome_text"
                      :value="campaignData.welcome_text"
                      @input="updateHandleInput"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Description</label>
                  <div class="col-11">
                    <TextareaInput
                      name="description"
                      :value="campaignData.description"
                      @input="updateHandleInput"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Upload Logo</label>
                  <div class="col-12">
                    <input
                      id="imageUpload"
                      type="file"
                      ref="logo"
                      name="logo"
                      :accept="fileType"
                      @change="handleFile"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="col-12">Upload Background</label>
                  <div class="col-12">
                    <input
                      name="background_image"
                      id="imageUpload"
                      type="file"
                      ref="background_image"
                      :accept="fileType"
                      @change="handleFile"
                    />
                  </div>
                </CRow>
                <CRow class="mb-3">
                  <label class="pt-1 col-lg-4 col-md-4 col-6"
                    >Enable pre-registration?</label
                  >
                  <CSwitch
                    name="campaign_registration_required"
                    class="mt-1"
                    color="success"
                    :checked="campaignData.campaign_registration_required"
                    shape="pill"
                    variant="3d"
                    @change.native="
                      updateHandleChangeCheck(
                        'campaign_registration_required',
                        $event
                      )
                    "
                  />
                </CRow>
                <CRow class="mb-3">
                  <label class="pt-1 col-lg-4 col-md-4 col-6"
                    >Make Inactive?
                  </label>
                  <CSwitch
                    name="is_active"
                    class="mt-1"
                    color="success"
                    :checked="!campaignData.is_active"
                    shape="pill"
                    variant="3d"
                    @change.native="updateHandleChangeCheck('is_active', $event)"
                  />
                </CRow>
                <CRow class="justify-content-end pr-3 mb-3">
                  <CButton
                    variant="outline"
                    color="primary"
                    class="mr-3"
                    type="button"
                    @click="showCampaignEdit = false"
                    >Cancel</CButton
                  >
                  <CButton
                    class="btn-primary"
                    type="submit"
                    :disabled="uploading"
                    ><CSpinner
                      v-if="uploading"
                      class="spinner-border-sm text-white m-1"
                    />
                    Update
                  </CButton>
                </CRow>
              </form>
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.fileData"
      modalColor="primary"
      :buttonLabel="cropperModal.buttonLabel"
      @modalCallBack="cropperModalCallBack"
    />
    <QrModal
      v-if="qrModal.isShowPopup"
      :modalTitle="qrModal.modalTitle"
      :modalColor="qrModal.modalColor"
      :isShowPopup="qrModal.isShowPopup"
      :buttons="qrModal.buttons"
      :qrUrl="qrUrl"
      :qrName="qrModal.qrName"
      :modalCallBack="qrModalCallBack"
      :closeOnBackdrop="true"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import TextInput from "@/components/reusable/Fields/TextInput";
import CropperModal from "@/components/reusable/CropperModal";
import Vue from "vue";
extend("required", { ...required, message: "This field is required" });
import m from "moment";
import { BASE_URL } from "@/service_urls";
import QrModal from "@/components/reusable/QrModal.vue";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import { deepClone } from "@/helpers/helper.js";
import SearchBar from "@/components/reusable/GenericFilterAndSearch/searchComponent";

export default {
  components: {
    TextInput,
    CropperModal,
    QrModal,
    RadioButton,
    TextareaInput,
    SearchBar,
  },
  data() {
    return {
      campaign: {
        campaign_registration_required: true,
      },
      fileType: "image/png, image/jpeg, image/jpg, image/gif",
      logoFile: null,
      backgroundFile: null,
      payload: {
        campaign_name: "",
        description: "",
        welcome_text: "",
      },
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
        aspectRatio: 1,
      },
      qrModal: {
        modalColor: "primary",
        modalTitle: "QR Code",
        isShowPopup: false,
        buttons: ["Download"],
      },
      campaignData: {
        campaign_name: "",
        description: "",
        welcome_text: "",
        campaign_registration_required: true,
        is_active: true,
      },
      showCampaignEdit: false,
      showDetails: false,
      selectedDescriptionIndex: 0,
      selectedCampaignIndex: 0,
      qrUrl: "",
      uploading: false,
      campaignsPerPage: 5,
      searchTerm: null,
      searchPages: 0,
      searched: null,
      isActiveFilter: null,
    };
  },
  watch: {
    $route(to, from) {
      const searchTerm = to.query.searchTerm;
      const page = Number(to.query.page) || 1;
      if (searchTerm && searchTerm !== this.searchTerm) {
        this.searchTerm = searchTerm;
        this.handleSearchTerm(this.searchTerm);
      }
      if(page)
      {
        this.pageChanged(page);
      }
    },
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getOrgID",
      "getCampaigns",
      "getPathAcronymFromAccessToken",
      "getCampaignsCount",
    ]),
    campaignsList() {
      let baseurl = BASE_URL;
      // baseurl = baseurl.split("/api")[0];
      baseurl = "https://" + window.location.host;
      return (
        this.getCampaigns.map((v) => {
          return {
            ...v,
            _logo: v?.uuid_logo_link_to_doc
              ? `${baseurl}${
                  v?.uuid_logo_link_to_doc
                }?file=logo&${new Date().getTime()}`
              : "/img/tflogo.png",
            campaignURL: `${baseurl}/${this.getPathAcronymFromAccessToken}/campaign/${v?.campaign_uuid}`,
          };
        }) || []
      );
    },
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
      };
    },
    pageCount() {
      if (!this.searchTerm) {
        return Math.ceil(this.getCampaignsCount / this.campaignsPerPage);
      } else {
        return Math.ceil(this.searchPages / this.campaignsPerPage);
      }
    },
    currentPage: {
      get() {
        const page = parseInt(this.$route.query.page) || 1;
        return page;
      },
      set(page) {
        if (page !== this.currentPage) {
          this.$router.push({ query: { ...this.$route.query, page } });
        }
      },
    },
    checkFilterIsActive() {
      if (this.isActiveFilter !== null && this.isActiveFilter !== undefined) {
        return this.isActiveFilter ? true : false;
      }
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "registerCampaign",
      "fetchCampaigns",
      "updateCampaignData",
      "downloadCampaignData",
      "fetchCampaignsCount",
    ]),
    showDescription(i) {
      this.showDetails = !this.showDetails;
      this.selectedDescriptionIndex = i;
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    handleFile(event) {
      const size = event.target.files[0].size;
      const fieldName = event?.target?.name;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.openCropper({ file, fieldName });
            this.tempFile = file;
          }
        } else {
          this.showToast({
            class: "bg-danger text-white",
            message: "Only png/jpeg/jpg/gif allowed",
          });
          this.$refs[fieldName].value = "";
        }
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "File too Big, please select a file less than 2mb",
        });
        this.$refs[fieldName].value = "";
      }
    },
    openCropper(data) {
      const { file, fieldName } = data;
      this.cropperModal.fileData = file;
      this.cropperModal.isShowPopup = true;
      this.cropperModal.fieldName = fieldName;
      const aspectRatio = {
        logo: 1,
        background_image: 2,
      };
      this.cropperModal.aspectRatio = aspectRatio[fieldName];
    },
    cropperModalCallBack(action, data) {
      const { fieldName } = this.cropperModal;
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
        this.$refs[fieldName].value = "";
      } else {
        let { fileBlob, fileName } = data;
        this.campaign = {
          ...this.campaign,
          [fieldName]: {
            file: fileBlob,
            fileName,
          },
        };
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.cropperModal.aspectRatio = 0;
      }
    },

    handleInput(name, value) {
      Vue.set(this.campaign, name, value);
      this.$refs.logo.value = "";
      this.$refs.background_image.value = "";
    },
    updateHandleInput(name, value) {
      Vue.set(this.campaignData, name, value);
    },
    resetForm() {
      this.$refs.campaignRegister.reset();
      this.campaign = { campaign_registration_required: true };
      this.$refs.logo.value = "";
      this.$refs.background_image.value = "";
    },
    redirectToCampaignForm() {},
    async onSubmit() {
      const isValid = await this.$refs.campaignRegister.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory field!",
        });
        return;
      }
      let finalPayload = {
        ...this.campaign,
        organisation_id: this.getOrgID,
        customer_id: this.getCustomerId,
        campaign_registration_required:
          this.campaign?.campaign_registration_required,
      };
      this.uploading = true;

      this.registerCampaign(finalPayload).then((res) => {
        if (res?.campaign_registration_required == true) {
          if (res?.campaign_uuid) {
            const { campaign_uuid } = res;
            this.$router.push({ path: `/campaign-form/${campaign_uuid}` });
          }
        } else {
          this.uploading = false;
          this.resetForm();
          this.pageChanged(this.currentPage);
        }
      });
    },
    formatDateFromNow(date) {
      return m(date).fromNow();
    },
    formatDate(date) {
      return date ? m(date).format("DD MMM YYYY") : "--";
    },
    goToCampaignForm(campaign) {
      if (campaign?.campaign_uuid) {
        this.$router.push({
          path: `/campaign-form/${campaign?.campaign_uuid}`,
        });
      }
    },
    showQr(campaign) {
      this.qrUrl = campaign.campaignURL;
      this.qrModal.qrName = campaign.campaign_name;
      this.qrModal.isShowPopup = true;
    },
    qrModalCallBack() {
      this.qrModal.isShowPopup = false;
    },
    campaignEdit(i) {
      this.showCampaignEdit = true;
      this.selectedCampaignIndex = this.campaignsList.indexOf(i);
      this.campaignData = deepClone(i);
    },
    async onUpdate() {
      const isValid = await this.$refs.editcampaigndetails.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory field!",
        });
        return;
      }
      this.uploading = true;
      let payload = {
        campaign_name: this.campaignData.campaign_name,
        campaign_registration_required:
          this.campaignData.campaign_registration_required,
        description: this.campaignData.description || "",
        welcome_text: this.campaignData.welcome_text || "",
        is_active: this.campaignData.is_active,
        campaign_uuid: this.campaignData.campaign_uuid,
      };
      if (this.campaign?.logo?.file) {
        payload.logo = this.campaign.logo;
      }
      if (this.campaign?.background_image?.file) {
        payload.background_image = this.campaign.background_image;
      }
      this.updateCampaignData(payload).then(() => {
        this.uploading = false;
        this.showCampaignEdit = false;
        this.resetForm();
        this.pageChanged(this.currentPage);
      });
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.code === false) {
        val = false;
      } else {
        val = value?.code || value;
      }
      Vue.set(this.campaign, name, val);
    },
    updateHandleChangeRadio(name, value) {
      let val = null;
      if (value?.code === false) {
        val = false;
      } else {
        val = value?.code || value;
      }
      Vue.set(this.campaignData, name, val);
    },
    handleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      if (name === "is_active") {
        Vue.set(this.campaign, name, !checked);
      } else {
        Vue.set(this.campaign, name, checked);
      }
    },
    updateHandleChangeCheck(name, $event) {
      const checked = $event.target.checked;
      if (name === "is_active") {
        Vue.set(this.campaignData, name, !checked);
      } else {
        Vue.set(this.campaignData, name, checked);
      }
    },
    fileDownload(campaign_name, campaign_id) {
      this.downloadCampaignData({ campaign_name, campaign_id });
    },
    pageChanged(page) {
      this.currentPage = page;
      const limit = this.campaignsPerPage;
      const skip = (this.currentPage - 1) * limit;
      const searchTerm = this.searchTerm;
      this.fetchCampaigns({
        limit,
        skip,
        searchTerm,
        is_active: this.checkFilterIsActive,
      });
    },
    async handleSearchTerm(item) {
      if(item === '')
      {
        this.clearSearchTerm()
      }
      else{
        if (item) {
          const searchTerm = item.trim();
          this.searched = searchTerm;
          this.currentPage = 1;
          const currentQueryParams = this.$route.query;
          const newQueryParams = {
            ...currentQueryParams,
            searchTerm: searchTerm,
          };
          this.$router.push({
            path: "/campaign-register",
            query: newQueryParams,
          });
          this.searchPages = await this.fetchCampaignsCount({
            searchTerm,
            is_active: this.checkFilterIsActive,
          });
          this.pageChanged(this.currentPage);
        } else {
          return;
        }
      }
      
    },
    async clearSearchTerm() {
      this.searched = "";
      this.searchTerm = "";
      this.currentPage = 1;
      const currentQueryParams = { ...this.$route.query };
      delete currentQueryParams.searchTerm;
      const newRoute = { path: this.$router.path, query: currentQueryParams };
      this.$router.replace(newRoute);
      await this.fetchCampaigns({
        limit: this.campaignsPerPage,
        skip: 0,
        searchTerm: "",
        is_active: this.checkFilterIsActive,
      });
      this.searchPages = await this.fetchCampaignsCount({
        searchTerm: this.searchTerm,
        is_active: this.checkFilterIsActive,
      });
      this.pageCount;
    },
    async clearIsActiveFilter() {
      this.isActiveFilter = null;
      const currentQueryParams = { ...this.$route.query };
      delete currentQueryParams.is_active;
      const newRoute = { path: this.$router.path, query: currentQueryParams };
      this.$router.replace(newRoute);
      await this.fetchCampaigns({
        limit: this.campaignsPerPage,
        skip: 0,
        searchTerm: this.searchTerm,
      });
      this.searchPages = await this.fetchCampaignsCount({
        searchTerm: this.searchTerm,
        is_active: this.checkFilterIsActive,
      });
      this.pageCount;
    },
    copyUrl(campaign) {
      try {
        const url = campaign.campaignURL;
        navigator.clipboard.writeText(url);
        this.showToast({
          class: "bg-success text-white",
          message: "URL Copied to Clipboard",
        });
      } catch (error) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Error copying URL to clipboard",
        });
      }
    },
    async showCampaignFilter(isActive) {
      this.isActiveFilter = isActive;
      this.currentPage = 1;
      this.pageChanged(this.currentPage, this.checkFilterIsActive);
      this.searchPages = await this.fetchCampaignsCount({
        searchTerm: this.searchTerm,
        is_active: this.checkFilterIsActive,
      });
      this.fetchCampaigns({
        skip: 0,
        limit: this.campaignsPerPage,
        searchTerm: this.searchTerm,
        is_active: this.checkFilterIsActive,
      });
      this.$router.push({
        query: { ...this.$route.query, is_active: this.checkFilterIsActive },
      });
    },
  },
  mounted() {
    this.getCampaigns;
  },
  async created() {
    const { page, searchTerm, is_active } = this.$route.query;
    this.isActiveFilter = is_active;
    if (searchTerm || page || is_active) {
      this.searched = searchTerm;
      this.searchTerm = searchTerm;
      this.searchPages = await this.fetchCampaignsCount({
        searchTerm,
        is_active,
      });
      this.pageChanged(parseInt(page));
    } else {
      this.searchPages = await this.fetchCampaignsCount();
      this.fetchCampaigns({ skip: 0, limit: this.campaignsPerPage });
    }
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 50px !important;
  height: 50px !important;
  img {
    min-width: 100% !important;
    max-height: 100% !important;
  }
}
.list-group-item:active {
  background-color: unset !important;
}
.list-group-item:active p {
  color: unset !important;
}
</style>
